import { faPhone } from "@fortawesome/fontawesome-free-solid";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { assetsUrl, routes } from "../config/constants";

const FooterRu = () => {
    return (
      <footer id="main-footer">
        <div className="footer-wrap">
          <div className="footer-column footer-legal-links">
            <div className="footer-column-center-wrapper ">
              <Link to={routes.legalInfoRoutes.companyDetails}>Реквизиты компании</Link>
              <Link to={routes.legalInfoRoutes.oferta}>Договор оферты</Link>
              <Link to={routes.legalInfoRoutes.returnPolicy}>Политика возврата билетов</Link>
              <Link to={routes.legalInfoRoutes.paymentProcess}>Порядок проведения оплаты и возвратов</Link>
            </div>
          </div>
          <div className="footer-column footer-logos">
            <div className="footer-logo-wrapper">
              <img className="payment-card-logo payment-card-logo-mir" src={`${assetsUrl}/payment-mir.png`} alt=''/>
              <img className="payment-card-logo" src={`${assetsUrl}/payment-mastercard.png`} alt=''/>
            </div>
            <div className="footer-logo-wrapper">
              <img className="payment-card-logo" src={`${assetsUrl}/payment-visa.png`} alt=''/>
              <img className="payment-card-logo" src={`${assetsUrl}/payment-maestro.png`} alt=''/>
            </div>
            <div className="footer-logo-wrapper">
              <img className="payment-card-logo payment-card-logo-modulbank" src={`${assetsUrl}/payment-modulbank.png`} alt=''/>
            </div>
          </div>
          <div className="footer-column footer-contacts">
            <div className="footer-column-center-wrapper">
              <a href="tel:+971528996301"><FontAwesomeIcon icon={faPhone as IconProp} /> +971 52 899 63 01</a>
              <a href='https://api.whatsapp.com/send?phone=%2B971528996301'>
                <FontAwesomeIcon size={"xs"} icon={faWhatsapp} />+971 52 899 63 01
              </a>
              <a target="_blank" href='https://api.whatsapp.com/send?phone=%2B79872253545' rel="noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} />+7 (843) 225-35-45
              </a>
              <a target="_blank" href='mailto:web@dubairelaxtours.com' rel="noreferrer">
                <FontAwesomeIcon icon={faEnvelopeOpen as IconProp} /> web@dubairelaxtours.ru
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  export default FooterRu