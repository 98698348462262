import React from 'react';

export type Props = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  mask?: Array<RegExp | string>;
  error?: boolean;
};

const Input = (props: Props) => (
    <input {...props} className={'custom-input ' + (props.error ? 'input-error' : '')} />
  );

export default Input;
