const translation = {
  title: 'Страница оплаты бронирования №',
  paymentPage:'Страница оплаты',
  bookingId:"Номер бронирования",
  date:"Дата",
  tourName: "Экскурсия",
  ticketName: "Билет",
  hotel:"Отель",
  guestName: "Имя гостя",
  contactNumber: "Телефон",
  roomNumber: "Номер комнаты",
  paxNumber:"Кол. людей",
  adults:"Взрослые",
  children:"Дети",
  infants:"Младенцы",
  foc:"FOC",
  costPerTrip:"Цена за поездку",
  cancellationPolicy:"Условия отмены",
  totalCostWithVat:"Итоговая стоимость",
  cost: "Цена",
  total: "Итого",
  email:"Адрес электронной почты",
  checkout: {
    guest: 'Информация о госте',
    selectHotel: 'Выберите отель',
    enterYourName: 'Введите ваше имя',
    enterYourRoom: 'Укажите номер комнаты',
    enterYourEmail: 'Ваш Email',
    selectMobile: 'Номер телефона',
    bookingSummary: 'Информация о бронировании',
    tourDate: 'Дата',
    pickUpTime: 'Во сколько вас заберут',
    selectHotelFirst: 'Сначала необходимо выбрать отель',
    ticketType: 'Тип билета',
    numberOfPeopleLabel: 'Количество людей',
    cancellationPolicyLabel: 'Условия отмены',
    cancellationPolicy: '48 часов - 100% / 24 часа - 50%',
    clock1: 'час',
    clock2: 'часа',
    clock3: 'часов',
    orMore: 'или больше до начала',
    returns: 'возврат',
  },
  confirm: {
    title: 'Бронирование подтверждено',
    tourName: 'Название тура',
    totalCost: 'Общая стоимость',
    guestName: 'Имя гостя',
    hotelName: 'Имя отеля',
    roomNumber: 'Номер комнаты',
    mobileNumber: 'Номер телефона',
    email: 'Электронная почта',
    ticketInfo: 'Копия ваучера была отправлена на указанный адрес электронной почты.',
    downloadVoucher: 'Скачать ваучер',
    downloadTickets: 'Скачать билеты',
    downloadInvoice: 'Скачать счет',
    information: 'В случае, если указанная информация неверна или у вас есть дополнительные вопросы, свяжитесь с нами свяжитесь с нами по указанным ниже контактам.',
    reservationTeamContacts: 'Контакты отдела бронирования',
    questions: 'Вы можете связаться с нами по любым вопросам'
  },
}

export default translation;