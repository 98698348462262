import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocalizationContext } from "../../contexts/localizationContext";

type Props = {
    children: ReactElement
}
const TranslationProvider = ({children} : Props) => {
    const { i18n } = useTranslation();
    const {language} = useLocalizationContext()
    useEffect(() => {
        if(i18n && i18n.changeLanguage) {
          i18n.changeLanguage(language)
        }
      }, [i18n, language])
      return children
}
export default TranslationProvider