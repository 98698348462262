import { useEffect, useState } from 'react';
import bookingApi from '../../lib/api/booking';
import { useParams } from 'react-router-dom';
import { Booking } from '../../types/booking';
import Payment from '../../components/payment';
import BookingSummary from '../../components/booking-summary';
import { modulbankId, modulbankSuccessUrl, modulbankTest, routes, tapPaymentPubKey } from '../../config/constants';
import PaymentModulbank from '../../components/paymentModul';
import { useLocalizationContext } from '../../contexts/localizationContext';
import TapPayment from '../../components/payment/tap-payment';
import { useQuery } from '../../hooks/use-query';

type Props = {
  ru?: boolean;
  tapPayment?: boolean;
};
const Main = ({ ru, tapPayment }: Props) => {
  const { setLanguage, currency, setCurrency } = useLocalizationContext();
  const params = useParams();
  const query = useQuery();

  useEffect(() => {
    if (ru) {
      setLanguage('ru');
      setCurrency('rub');
    }
  }, [ru, setCurrency, setLanguage]);
  const [booking, setBooking] = useState<Booking>();

  useEffect(() => {
    const getBooking = async () => {
      const data = params.guid && (await bookingApi.getBooking(params.guid, ru ? 'rub' : currency));
      data && setBooking(data);
    };
    getBooking();
  }, [currency, params.guid, ru]);

  return booking ? (
    <div className="container">
      <BookingSummary booking={booking} />
      {booking.isPayable ? (
        ru ? (
          <PaymentModulbank
            amount={booking.totalPayable}
            amountUsd={booking.totalPayableUsd}
            convRate={booking.convRate}
            booking={booking}
            isValid={true}
            ticketType={booking.offerName}
            modulbankId={modulbankId || ''}
            modulbankSuccessUrl={modulbankSuccessUrl || ''}
            modulbankTest={modulbankTest}
            legalInfoPaymentProcessUrl={routes.legalInfoRoutes.paymentProcess}
          />
        ) : tapPayment && tapPaymentPubKey ? (
          query.get('tap_id') ? (
            !booking.paymentErrorMessage ? (
              <>Please wait, payment is being processed</>
            ) : (
              <>
                {' '}
                {booking.paymentErrorMessage}
                <TapPayment
                  bookingGuid={booking.guid}
                  paymentIntent={booking.paymentIntent}
                  amount={booking.totalPayable}
                />
              </>
            )
          ) : (
            <TapPayment
              bookingGuid={booking.guid}
              paymentIntent={booking.paymentIntent}
              amount={booking.totalPayable}
            />
          )
        ) : (
          <Payment bookingGuid={booking.guid} paymentIntent={booking.paymentIntent} amount={booking.totalPayable} />
        )
      ) : (
        !!booking.notPayableDescription?.length && (
          <div className="panel">
            {booking.notPayableDescription?.map((item) => (
              <div>{item}</div>
            ))}
          </div>
        )
      )}
      {/*<Accordeon items={faq} />*/}
    </div>
  ) : null;
};

export default Main;
