import { useLocalizationContext } from "../contexts/localizationContext";
import FooterRu from "./footerRu";

const Footer = () => {
  const {language, currency} = useLocalizationContext()
  if(language === 'ru' && currency === 'rub'){
    return <FooterRu/>
  }
  return null
}

export default Footer;
