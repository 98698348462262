import React, { useCallback, useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import Button from '../../components/button';
import Input from '../../components/input';
import { PaymentIntent } from '../../types/payment';
import bookingApi from '../../lib/api/booking';

export type PaymentProps = {
  bookingGuid: string;
  paymentIntent?: PaymentIntent;
  amount: number;
};

const Payment = (props: PaymentProps) => {
  const [request, setRequest] = useState({ amount: props.amount || '0', currency: 'aed', guid: props.bookingGuid });
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);
  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentMethodReceived = useCallback(
    async (e: PaymentRequestPaymentMethodEvent) => {
      const paymentIntent = props.paymentIntent ? props.paymentIntent : await bookingApi.createPayment(request);
      const payload = await stripe?.confirmCardPayment(
        paymentIntent.clientSecret,
        {
          payment_method: e.paymentMethod.id,
        },
        {
          handleActions: false,
        }
      );
      if (payload?.error) {
        e.complete('fail');
        return;
      } else {
        e.complete('success');
        toast.success('Success');
      }
    },
    [props.paymentIntent, request, stripe]
  );

  useEffect(() => {
    if (stripe && !paymentRequest) {
      const pr = stripe.paymentRequest({
        country: 'AE',
        currency: 'aed',
        total: {
          label: 'referer',
          amount: Math.floor(props.amount * 100),
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on('paymentmethod', handlePaymentMethodReceived);
          // @ts-ignore
          setPaymentRequest(pr);
        }
      });
    }
  }, [handlePaymentMethodReceived, paymentRequest, props.amount, stripe]);

  const handleChange = async (event: any) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    if (disabled) {
      setError('Please enter your credit card details.');
      return;
    }
    if (!stripe || !elements) {
      console.log('Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.');
      return;
    }
    setProcessing(true);

    const paymentIntent = props.paymentIntent ? props.paymentIntent : await bookingApi.createPayment(request);
    if (!paymentIntent) {
      setProcessing(false);
      return;
    }
    const cardElement = elements?.getElement(CardNumberElement);
    const payload = await stripe?.confirmCardPayment(paymentIntent.clientSecret, {
      payment_method: {
        // @ts-ignore
        card: cardElement,
      },
    });

    if (payload?.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      toast.success('Success');
    }
  };

  return (
    <form id="payment-form" className="checkout-box-wrap" onSubmit={handleSubmit}>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert">
          {error}
        </div>
      )}
      {!props.amount && (
        <label className="card-element">
          <span>Amount</span>
          <Input
            type="number"
            onChange={(event) => setRequest((prev) => ({ ...prev, amount: event.target.value }))}
            value={request.amount}
            placeholder="Amount"
          />
        </label>
      )}
      <label className="card-element">
        <span>Card number</span>
        <CardNumberElement options={{ ...cardStyle, showIcon: true }} onChange={handleChange} />
      </label>
      <label className="card-element">
        <span>Expiration date</span>
        <CardExpiryElement options={cardStyle} onChange={handleChange} />
      </label>
      <label className="card-element">
        <span>CVC</span>
        <CardCvcElement options={cardStyle} onChange={handleChange} />
      </label>
      <div className="payment-button-container">
        <Button
          disabled={processing || succeeded}
          submit
          // @ts-ignore
          iconLeft={() => (processing ? <Loader type="Circles" color="#FFF" height={20} width={20} /> : null)}
          label={disabled ? 'Enter card details' : `Book now ${props.amount || request.amount} AED`}
          size="medium"
          color={disabled ? 'gray' : 'blue'}
          fullWidth
        />
      </div>
      {/* Show a success message upon completion */}
      <p className={succeeded ? 'result-message' : 'result-message hidden'}>Payment succeeded</p>

      <div className="payment-button-container">
        {/*@ts-ignore*/}
        {paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}
      </div>
    </form>
  );
};

export default Payment;

const cardStyle = {
  style: {
    base: {
      color: '#32325d',
      border: '1px',
      fontSmoothing: 'antialiased',
      fontSize: '18px',
      lineHeight: '26px',
      '::placeholder': {
        color: '#848484',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};
