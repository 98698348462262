import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as IconInfo } from '../../assets/images/icons/icon-info.svg';
import { ReactComponent as IconDownload } from '../../assets/images/icons/icon-download.svg';
import { ReactComponent as IconTicketActive } from '../../assets/images/icons/icon-ticket-active.svg';
import { ReactComponent as IconAccept } from '../../assets/images/icons/icon-accept.svg';
import { ReactComponent as IconPhone } from '../../assets/images/icons/icon-phone.svg';
import { ReactComponent as IconEmail } from '../../assets/images/icons/icon-email.svg';
import ContactAvatar from '../../assets/images/contact-avatar.png';
import Button from '../../components/button';
import bookingApi from '../../lib/api/booking';
import { Booking } from '../../types/booking';
import format from 'date-fns/format';
import addMinutes from 'date-fns/addMinutes';
import { emptyDate } from '../../config/constants';
import { useTranslation } from 'react-i18next';
import {Helmet} from "react-helmet";
import { useLocalizationContext } from '../../contexts/localizationContext';



const Confirm = () => {
  const { setLanguage, setCurrency} = useLocalizationContext()
  const [searchParams] = useSearchParams();
  // const { transactionId } = useParams<{ transactionId: string }>();
  const [t] = useTranslation();
  useEffect(() => {
    setLanguage('ru')
    setCurrency('rub')
}, [setCurrency, setLanguage])
  // States
  const [booking, setBooking] = useState<Booking>();
  const [isLoading, setLoading] = useState(true);

  // Effects
  useEffect(() => {
    console.log('fetch')
    const fetchData = async () => {
      console.log(searchParams.keys())
      const transactionId = searchParams.get('transaction_id');
      if(!transactionId) return
      const data = await bookingApi.getBookingByTransactionId(transactionId);
      setBooking(data);
      setLoading(false)
    };
    fetchData();
  }, [searchParams]);

 

  return (
    <>
    <Helmet>
      
    </Helmet>
    <div className='confirm-container'>
      <h2 className='booking?-title'>{t('confirm.title')}</h2>
      <div className='confirm-table'>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('checkout.tourDate')}</div>
          <div className='confirm-table-cell'>
            {(booking && booking.startTime && booking.endTime) &&
            `${format(new Date(booking.date), 'dd.MM.yyyy')} (${format(
              addMinutes(emptyDate, booking.startTime),
              'HH-mm'
            )} - ${format(addMinutes(emptyDate, booking.endTime), 'HH-mm')})`}
          </div>
        </div>
        {booking?.hotelName && (
          <div className='confirm-table-row'>
            <div className='confirm-table-cell'>{t('checkout.pickUpTime')}</div>
            <div className='confirm-table-cell'>{`${format(
              addMinutes(emptyDate, booking.pickUpTimeStart),
              'HH-mm'
            )} - ${format(addMinutes(emptyDate, booking.pickUpTimeEnd), 'HH-mm')}`}</div>
          </div>
        )}
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.tourName')}</div>
          <div className='confirm-table-cell'>{booking?.offerName}</div>
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('checkout.numberOfPeopleLabel')}</div>
          <div className='confirm-table-cell'>
            {booking?.numAdults} {t('adults')} {booking?.numChildren ? `/ ${booking?.numChildren} ${t('child')} ` : ''}{' '}
            {booking?.numInfants ? `/ ${booking?.numInfants} ${t('infant')} ` : ''}
          </div>
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell confirm-table-cell__total'>{t('confirm.totalCost')}</div>
          <div className='confirm-table-cell confirm-table-cell__total'>
            <strong>{booking?.totalCost} AED</strong>
          </div>
        </div>
      </div>

      <div className='confirm-table'>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.guestName')}</div>
          <div className='confirm-table-cell'>{booking?.guestName}</div>
        </div>
        {booking?.hotelName && (
          <>
            <div className='confirm-table-row'>
              <div className='confirm-table-cell'>{t('confirm.hotelName')}</div>
              <div className='confirm-table-cell'>{booking.hotelName}</div>
            </div>
            <div className='confirm-table-row'>
              <div className='confirm-table-cell'>{t('confirm.roomNumber')}</div>
              <div className='confirm-table-cell'>{booking.roomNumber}</div>
            </div>
          </>
        )}
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.mobileNumber')}</div>
          <div className='confirm-table-cell'>{booking?.phone}</div>
        </div>
        <div className='confirm-table-row'>
          <div className='confirm-table-cell'>{t('confirm.email')}</div>
          <div className='confirm-table-cell'>{booking?.email}</div>
        </div>
      </div>

      <div className='get-ticket-wrap'>
        <div className='get-ticket-info'>
          <IconInfo />{t('confirm.ticketInfo')}
        </div>
        <div className='get-ticket-buttons'>
          {booking?.voucherUrl && (
            <Button
              label={t('confirm.downloadVoucher')}
              color='green'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.voucherUrl)}
              iconLeft={() => <IconDownload />}
            />
          )}
          {booking?.ticketsUrl && (
            <Button
              label={t('confirm.downloadTickets')}
              color='blue'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.ticketsUrl)}
              iconLeft={() => <IconTicketActive />}
            />
          )}
          {booking?.invoiceUrl && (
            <Button
              label={t('confirm.downloadInvoice')}
              color='dark-blue'
              customClassName='confirm-button'
              size='large'
              onClickHandler={() => (window.location.href = booking.invoiceUrl)}
              iconLeft={() => <IconAccept />}
            />
          )}
        </div>
        <p>
          {t('confirm.information')}
        </p>
      </div>
      <div className='booking-box-wrap confirm-box-contacts'>
        <h3>{t('confirm.reservationTeamContacts')}</h3>
        <p>{t('confirm.questions')}</p>
        <div className='confirm-contact-info'>
          <div className='confirm-contact-info--image'>
            <img src={ContactAvatar} alt='Joan Bantay Fajardo' />
          </div>
          <div className='confirm-contact-info--content'>
            <h5>Joan Bantay Fajardo</h5>
            <ul>
              <li className='confirm-contact-info--tel'>
                <a href='tel:+971528996310'>
                  <IconPhone />
                  <span>+971</span> 52 899 63 10
                </a>
              </li>
              <li className='confirm-contact-info--email'>
                <a href='mailto:dhowcruise@absea.ae'>
                  <IconEmail />
                  dhowcruise@absea.ae
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</>
  );
};

export default Confirm;
