import React, {useCallback, useState} from 'react';
import Loader from 'react-loader-spinner';
import {LockIcon} from '../icons';
import {PaymentProps} from '../payment/index';
import bookingApi from "../../lib/api/booking";
import {tapPaymentPubKey} from '../../config/constants';
import GoSellElements from "../gosell/elements/GoSellElements";
import Button from "../button";

const TapPayment = ({ bookingGuid, amount }: PaymentProps) => {
    const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const getPaymentIntent = useCallback(
    async (cardToken: string) => await bookingApi.withTapPaymentCharge(
          bookingGuid,
          amount || 0,
          'aed',
          cardToken
      ),
    [amount, bookingGuid],
  );

  const callbackFunc = async (response: any) => {
    const payment = await getPaymentIntent(response.id);
    try {
      window.location.href = payment.transaction.url;
    } finally {
      setProcessing(false);
    }
  };

    console.log(tapPaymentPubKey)
  return (
    <div>
      <GoSellElements
        gateway={{
          publicKey: tapPaymentPubKey,
          language: 'en',
          supportedCurrencies: 'all',
          supportedPaymentMethods: 'all',
          notifications: 'tap-payment-notifications',
          callback: callbackFunc,
          labels: {
            cardNumber: 'Card Number',
            expirationDate: 'MM/YY',
            cvv: 'CVV',
            cardHolder: 'Name on Card',
            actionButton: 'Pay',
          },
          style: {
            base: {
              color: '#535353',
              lineHeight: '12px',
              fontFamily: 'sans-serif',
              fontSmoothing: 'antialiased',
              fontSize: '16px',
              '::placeholder': {
                color: 'rgba(0, 0, 0, 0.26)',
                fontSize: '15px',
              },
            },
            invalid: {
              color: 'red',
              iconColor: '#fa755a',
            },
          },
        }}
      />
      <p id="tap-payment-notifications" className={'result-message'}></p>
      <Button
        disabled={processing}
        onClickHandler={() => {
          setProcessing(true);
          GoSellElements.submit();
        }}
          // @ts-ignore
        iconLeft={() => (processing ? <Loader type="Circles" color="#FFF" height={20} width={20} /> : null)}
        label={disabled ? 'Enter card details': `Book now ${amount} AED`}
        size="medium"
        color="blue"
        fullWidth
      />
    </div>
  );
};

export default TapPayment;
