const translation = {
  title: 'Payment Page for Booking ID',
  paymentPage:'Payment Page',
  bookingId:"Booking Id",
  date: "Date",
  tourName: "Tour Name",
  ticketName: "Ticket Name",
  hotel:"Hotel",
  guestName:"Guest Name",
  contactNumber: "Contact #",
  roomNumber:"Room #",
  paxNumber:"#pax",
  adults:"Adults",
  children:"Children",
  infants:"Infants",
  foc:"FOC",
  costPerTrip:"Cost Per Trip",
  cancellationPolicy:"Cancellation Policy",
  totalCostWithVat:"Total Cost Inc. VAT",
  cost: "Cost",
  total: "Total",
  checkout: {
    guest: 'Guest Details',
    selectHotel: 'Select Hotel',
    enterYourName: 'Enter Your Name',
    enterYourRoom: 'Enter Your Room number',
    enterYourEmail: 'Enter Your Email',
    selectMobile: 'Enter Your Mobile Number',
    bookingSummary: 'Booking Summary',
    tourDate: 'Tour Date',
    pickUpTime: 'Pick Up Time',
    selectHotelFirst: 'Select Hotel First',
    ticketType: 'Ticket Type',
    numberOfPeopleLabel: 'Number of People',
    cancellationPolicyLabel: 'Cancellation Policy',
    cancellationPolicy: '48 Hours - 100% / 24 Hours - 50%',
    clock1: 'Hours',
    clock2: 'Hours',
    clock3: 'Hours',
    orMore: 'or more',
    returns: 'return',
  },
  confirm: {
    title: 'Booking Confirmed',
    tourName: 'Tour Name',
    totalCost: 'Total Cost',
    guestName: 'Guest Name',
    hotelName: 'Hotel Name',
    roomNumber: 'Room number',
    mobileNumber: 'Mobile Number',
    email: 'Email ID',
    ticketInfo: 'A copy of your voucher has been sent to your email.',
    downloadVoucher: 'Download Voucher',
    downloadTickets: 'Download Tickets',
    downloadInvoice: 'Download Invoice',
    information: ' Incase any of the above information is incorrect or you have any\n further questions contact details below.',
    reservationTeamContacts: 'Reservation team contacts',
    questions: 'Incase you have any questions, please contact.'
  },
}

export default translation;