const round = (value: number, decimals: number = 2) => {
    if(typeof(value) == 'undefined') return value
    const strVal = value.toString()
    if(strVal.indexOf('.') === -1 || strVal.split('.')[1].length <= decimals){
        return value
    }
    const rounder = Math.pow(10,decimals);
    return Math.round(value*rounder)/rounder;
}
export default round
