import { createContext, useContext } from "react"
export type Localization = {
    language: string
    setLanguage:(l: string) => void
    currency: string
    setCurrency:(s: string) => void
}
export const LocalizationContext = createContext<Localization>({
    language: 'en', 
    currency: 'aed',
    setLanguage: (l: string) => {},
    setCurrency: (s: string) => {}

})
export const useLocalizationContext = () => useContext(LocalizationContext)
