import { apiUrl } from '../../config/constants';
import { fetchRequest } from './index';
import { Payment, RuPaymentDetailsPreset } from '../../types/payment';


const getPayment = async (paymentId: string): Promise<Payment> =>
  await fetchRequest.get(`${apiUrl}/payments/${paymentId}`);
const getRuPaymentSignature = async (values: {fieldName: string, value: string}[]): Promise<{sbp: string, card: string}> =>
  await fetchRequest.post(`${apiUrl}/payments/signature`, {values});
const setRuPaymentDetails = async(details: RuPaymentDetailsPreset):Promise<boolean> => 
  await fetchRequest.post(`${apiUrl}/payments/detailsru`, details);
const payment = {
  getPayment, getRuPaymentSignature, setRuPaymentDetails
};
export default payment;
