import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from '../../config/constants';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import Main from '../main';
import Oferta from '../legal/oferta';
import CompanyDetails from '../legal/companyDetails';
import ReturnPolicy from '../legal/returnPolicy';
import PaymentsProcess from '../legal/paymentsProcess';
import Footer from '../../footer';
import { useLocalizationContext } from '../../contexts/localizationContext';
import Confirm from '../confirm';

const MyRoutes = () => {
  const { language } = useLocalizationContext();
  return (
    <BrowserRouter>
      <Routes>
        <Route path={routes.main} element={<Main />} />
        <Route path={routes.mainru} element={<Main ru />} />
        <Route path={routes.mainTapPayment} element={<Main tapPayment />} />
        <Route path={`${routes.confirm}`} element={<Confirm />} />

        {language === 'ru' ? (
          <React.Fragment>
            <Route path={`${routes.legalInfoRoutes.oferta}`} element={<Oferta />} />
            <Route path={`${routes.legalInfoRoutes.companyDetails}`} element={<CompanyDetails />} />
            <Route path={`${routes.legalInfoRoutes.returnPolicy}`} element={<ReturnPolicy />} />
            <Route path={`${routes.legalInfoRoutes.paymentProcess}`} element={<PaymentsProcess />} />
          </React.Fragment>
        ) : null}
      </Routes>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default MyRoutes;
