import { apiUrl } from '../../config/constants';
import { fetchRequest } from './index';
import { Booking } from '../../types/booking';
import {PaymentIntent, PaymentIntentCharge, PaymentRequest} from "../../types/payment";

const getBookingByTransactionId = async (transactionId: string) : Promise<Booking> => 
    await fetchRequest.get(`${apiUrl}/bookings/byrutransactionid/${transactionId}`);

const getBooking = async (guid: string, currency: string): Promise<Booking> =>
    await fetchRequest.get(`${apiUrl}/bookings/payment/${guid}?currencycode=${currency}`);

const createPayment = async (
    request: PaymentRequest
): Promise<PaymentIntent> =>
    await fetchRequest.post(`${apiUrl}/bookings/paymentintent`, request);
const withTapPaymentCharge = async (
    guid: string,
    amount: number,
    currency: string,
    cardToken: string
): Promise<PaymentIntentCharge> =>
    await fetchRequest.post(`${apiUrl}/bookings/tapcharge`, {guid, amount, currency, cardToken});


const bookingApi = {
    getBookingByTransactionId,
    getBooking,
    createPayment,
    withTapPaymentCharge
};
export default bookingApi;
