import React, {useEffect, useState} from "react";
import MyRoutes from "./containers/router";
import { Elements } from '@stripe/react-stripe-js';
import stripeJs from "@stripe/stripe-js";
import {stripePubKey} from "./config/constants";
import { loadStripe } from '@stripe/stripe-js/pure';
import './assets/styles/app.sass';
import './i18n';
import { LocalizationContext } from "./contexts/localizationContext";
import TranslationProvider from "./providers/translationProvider";



const App = () => {
  const [language, setLanguage] = useState<string>("en")
  const [currency, setCurrency] = useState<string>("aed")
 
  
  const [stripePromise, setStripePromise] = useState<PromiseLike<stripeJs.Stripe | null> | stripeJs.Stripe | null>();
  // Effects
  useEffect(() => {
    const _stripePromise = loadStripe(stripePubKey);
    setStripePromise(_stripePromise);
  }, []);
  return stripePromise ? (
    <LocalizationContext.Provider value={{language, setLanguage, currency,setCurrency }}>
      <TranslationProvider>
        <Elements stripe={stripePromise}>
          <MyRoutes/>
        </Elements>
      </TranslationProvider>
    </LocalizationContext.Provider>) : null
}



export default App;